import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Form } from "react-bootstrap";

function AmegaCleaning(props) {
  const [paymentTypeValue, setPaymentTypeValue] = useState("Нал");



  useEffect(() => {
    props.updateCleaningData({ 'terminal_fot_cleaning_payment_type': "Нал" });
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row
        className="border border-primary rounded-4"
        style={{
          boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.2)",
          padding: 0,
          paddingTop: "5px",
          marginLeft: "1px",
          marginBottom: "15px",
        }}
      >
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_a_c">
            <Form.Label>{props.promo !== 0 ? "Клининг (с учетом акции)": "Клининг"}</Form.Label>
            <Form.Control
              size="sm"
              defaultValue=""
              name="terminal_amega_cleaning"
              placeholder={props.finalCleaningSum}
              disabled
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_a_f_c">
            <Form.Label>ФОТ Клининг</Form.Label>
            <Form.Control
              size="sm"
              defaultValue=""
              name="terminal_amega_fot_cleaning"
              placeholder={props.masterWageCleaning}
              disabled
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3">
            <Form.Label>Тип платежа ФОТ клининг</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_fot_smp_payment_type"
              value={paymentTypeValue}
              onChange={(e) => {
                setPaymentTypeValue(e.target.value);
                props.updateCleaningData({
                  ...props.cleaningData,
                  'terminal_fot_cleaning_payment_type': e.target.value,
                });
              }}
            >
              <option selected value={"Нал"}>
                {"Нал"}
              </option>
              <option selected value={"Безнал"}>
                {"Безнал"}
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              size="sm"
              name={`terminal_fot_smp_comment`}
              placeholder="Примечание"
              type="text"
              onChange={(e) => {
                props.updateCleaningData({
                  ...props.cleaningData,
                  'terminal_fot_cleaning_comment': e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default AmegaCleaning;
