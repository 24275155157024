import React, {useEffect, useContext, useState} from 'react'
import "bootstrap/dist/css/bootstrap.css";
import {Container, Row, Col, Card, Form, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logout from '../login/Logout';
import NavTabs from './NavTabs';
import RemainderCard from '../general/RemainderCard';
import { UserContext } from '../UserContextManager';
import { NUContext } from '../NUHelpersManager';
import UserInfo from '../general/UserInfo';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import { DeskChangeBtn } from '../general/DeskChangeBtn';

function NuDesk(){

  const uContext = useContext(UserContext);
  const nContext = useContext(NUContext);
  const [responsibleUserData, setResponsibleUserData] = useState([]);

  const [foremanDraftData, setForemanDraftData] = useState(0);
  const [rentData, setRentData] = useState(0);

  const [foreman, setForeman] = useState('')
  const [rentDate, setRentDate] = useState('')

  const responsibleRef = React.createRef();

  const navigate = useNavigate();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const loadUserData = async () => {

    let userDataRes = await axios.get('/redesk/general/remainder', {params:{cfo: 'nu'}});

    let data = await userDataRes.data;

    let draftRes = await axios.get('/redesk/draft');

    let draftData = await draftRes.data;

    data.user_data['draft_data'] = draftData.draft_data;

    uContext.setUserData(data.user_data);
    
    
    let roles = data.user_data.role.split(';')
    if (roles.includes('nu')){
      navigate('/nu')    
    }else{
      navigate('/');
    }
  }

  const loadNonCashHelper = async (emonth=month, eyear=year) => {

    let res = await axios.get('/redesk/nu/helpers/noncash', {params:{year: eyear, month: emonth}});

    let conCopy = nContext.helpersData

    conCopy.revenue_helper = res.data.noncash_data.revenue
    conCopy.noncash_helper = res.data.noncash_data.noncash
    conCopy.output_helper = 0
    conCopy.output_all_helper = 0
    conCopy.main_helper = 0
    conCopy.np_helper = 0

    nContext.setHelpersData(conCopy);
  }

  const loadResponsibleUserData = async () => {
    let res = await axios.get('/redesk/terminal/responsible-users');
    setResponsibleUserData(res.data.responsible_users);
  }

  const handleForemanDraft = async (foreman) => {
    if (responsibleUserData.indexOf(foreman) > -1){
      let res = await axios.get('/redesk/nu/foreman-data', {params:{foreman: foreman}});
      setForemanDraftData(parseFloat(res.data.foreman_data).toFixed(2));
    }else{
      setForemanDraftData(0);
    }
  }
  
  const handleRented = async (f, d) => {
    if(responsibleUserData.indexOf(f) < 0){
      setRentData(0)
      return
    }

    let dateParts = d.split("/")
    if (dateParts.length < 3){
      setRentData(0)
      return
    }

    for (let i = 0; i < dateParts.length; i++) {
      if(dateParts[i].indexOf('_') > -1){
        setRentData(0)
        return
      }
    }

    if(!isNaN(Date.parse(+dateParts[2], dateParts[1] - 1, +dateParts[0]))){
      let res = await axios.get('/redesk/nu/rent-data', {params:{foreman: f, date: d}});
      setRentData(parseFloat(res.data.rent_data).toFixed(2));
    }else{
      setRentData(0)
      return
    }
  }

  useEffect(() => {
    let mounted = true;
    if (mounted){
      loadUserData();
      loadNonCashHelper();
      loadResponsibleUserData();
    }
    return () => mounted = false;
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row className="justify-content-center" style={{'marginTop': '15px'}}>
        <Col xs={6} style={{textAlign: "left"}}>
          <UserInfo />
        </Col>
        <Col xs={6} style={{textAlign: "right"}}>
          {uContext.userData.role.split(';').length > 1 ? 
          (<DeskChangeBtn />) : (<></>)
          }
          <Logout />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5" style={{'marginTop': '30px'}}>
      <Col sm={12} lg={2}>
        <Card
          bg='primary'
          key='noncash_percent_helper'
          text='white'
          style={{width: '100%'}}
          className="mb-2"
        >
          <Card.Header>Выручка и безнал</Card.Header>
          <Card.Body>
            <Row>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="h_y">
                <Form.Select size="sm" value={year} name="year" onChange={(e) => {setYear(e.target.value); loadNonCashHelper(undefined, e.target.value);}}>
                  <option key="2025" value={2025}>2025</option>
                  <option key="2024" value={2024}>2024</option>
                  <option key="2023" value={2023}>2023</option>
                  <option key="2022" value={2022}>2022</option>
                  <option key="2021" value={2021}>2021</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={6}>
            <Form.Group className="mb-3" controlId="h_m">
              <Form.Select value={month} size="sm" name="month" onChange={(e) => {setMonth(e.target.value); loadNonCashHelper(e.target.value);}}>
                {[...Array(12)].map((e, i) => <option key={i+1} value={i+1}>{i+1}</option>)}
              </Form.Select>
            </Form.Group>
            </Col>
            </Row>
            <Card.Text>

              <strong>Выручка: </strong>
              <NumberFormat
                value={nContext.helpersData.revenue_helper}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator={'.'}
                suffix={' ₽'}
                />
                <br />
              <strong>Процент безнала: </strong> {nContext.helpersData.noncash_helper} %
            </Card.Text>
          </Card.Body>
        </Card>
        <RemainderCard
          bgColor='light'
          keyProp='main_helper'
          textColor='black'
          title='Предоплата Осн. работы'
          value={nContext.helpersData.main_helper.toFixed(2)}
          tooltipText='Сумма предоплат по основным работам'
        />
        <RemainderCard
          bgColor='light'
          keyProp='np_helper'
          textColor='black'
          title='Предоплата НП'
          value={nContext.helpersData.np_helper.toFixed(2)}
          tooltipText='Сумма предоплат по "НП" и "Монтаж НП"'
        />
        <RemainderCard
          bgColor='light'
          keyProp='np_helper'
          textColor='black'
          title='Выработали (основные работы)'
          value={nContext.helpersData.output_helper.toFixed(2)}
          tooltipText='Сумма выручки по основным работам'
        />
        <RemainderCard
          bgColor='light'
          keyProp='np_helper'
          textColor='black'
          title='Выработали (все виды работ)'
          value={nContext.helpersData.output_all_helper.toFixed(2)}
          tooltipText='Сумма выручки по всем работам'
        />
        <RemainderCard
          bgColor='light'
          keyProp='np_helper'
          textColor='black'
          title='Кредит'
          value={nContext.helpersData.loan.toFixed(2)}
          tooltipText='Сумма по кредитам'
        />
      </Col>
        <Col sm={12} lg={8} className='p-0 mb-5'>
          <NavTabs />
        </Col>
        <Col sm={12} lg={2}>
        <Card
          bg='primary'
          key='foremna_info'
          text='white'
          style={{width: '100%'}}
          className="mb-2"
        >
        <Card.Header>Сколько должен сдать</Card.Header>
        <Card.Body>
          <Row>
          <Form.Group as={Col} className="mb-3" controlId="t_r_u">
          <Typeahead
            id='nu_foremna_info'
            name='nu_foremna_info'
            ref = {responsibleRef}
            labelKey={'value'}
            placeholder='Прораб'
            maxResults={7}
            emptyLabel='Нет совпадений'
            paginationText='Показать больше'
            highlightClassName='font-weight-bold'
            onChange={(selected) => {handleForemanDraft(selected[0]); setForeman(selected[0]); handleRented(selected[0], rentDate);}}
            onInputChange={(text) => {handleForemanDraft(text); setForeman(text); handleRented(text, rentDate);}}
            options={responsibleUserData}
            size='sm'
            renderMenuItemChildren={(option, props, idx) => (
              <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                {option}
              </Highlighter>
            )}
          />
          </Form.Group>
          <Form.Group className='mb-2'>
            <MaskedFormControl size='sm' type='text' name='date' placeholder='Дата' value={rentDate} mask='11/11/1111' onChange={(e)=>{setRentDate(e.target.value); handleRented(foreman, e.target.value);}}/>
          </Form.Group>
          </Row>
          <Card.Text>
          <strong>В черновике: </strong>
              <NumberFormat
                value={foremanDraftData}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator={'.'}
                suffix={' ₽'}
              />
          <br />
          <strong>Сдано ранее: </strong>
              <NumberFormat
                value={rentData}
                displayType={'text'}
                thousandSeparator={' '}
                decimalSeparator={'.'}
                suffix={' ₽'}
              />
          </Card.Text>
        </Card.Body>
        </Card>
          <RemainderCard
            bgColor='primary'
            keyProp='nu_main_remainder'
            textColor='white'
            title='Общий остаток'
            value={(uContext.userData.nu_main_remainder + uContext.userData.nu_outcoming_reas_remainder).toFixed(2)}
            tooltipText='Разница между наличными приходами и наличными расходами'
            tooltipPlacement='left'
          />
          <RemainderCard
            bgColor='light'
            keyProp='nu_outcoming_reas_remainder'
            textColor='black'
            title='Исходящие переброски'
            value={uContext.userData.nu_outcoming_reas_remainder.toFixed(2)}
            tooltipText='Сумма всех исходящих наличных неподтвержденных перебросок'
            tooltipPlacement='left'
          />
          <RemainderCard
            bgColor='light'
            keyProp='nu_incoming_reas_remainder'
            textColor='black'
            title='Входящие переброски'
            value={uContext.userData.nu_incoming_reas_remainder.toFixed(2)}
            tooltipText='Сумма всех входящих наличных неподтвержденных перебросок'
            tooltipPlacement='left'
          />
        </Col>
      </Row>
    </Container>
  );
}

export default NuDesk
