import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import {
  Row,
  Col,
  Button,
  Stack,
  Form,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import MasterFot from "./fotAddComponents/MasterFot";
import FoTAddon from "./fotAddComponents/FotAddon";
import { v4 as uuidV4 } from "uuid";
import { UserContext } from "../UserContextManager";
import { NUContext } from "../NUHelpersManager";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import cookies from "js-cookie";
import AmegaCleaning from "./amegaAddComponents/AmegaCleaning";
import AmegaSMP from "./amegaAddComponents/AmegaSMP";
import AmegaDoors from "./amegaAddComponents/AmegaDoors";
import AmegaPrepaymentNP from "./amegaAddComponents/AmegaPrepaymentNP";

function AmegaTerminal(props) {
  const nContext = useContext(NUContext);
  const uContext = useContext(UserContext);

  const objectRef = React.createRef();
  const [objectsList, setObjectsList] = useState([]);
  const [objectId, setObjectId] = useState(null);

  const dealRef = React.createRef();
  const [objectDetail, setObjectDetail] = useState([]);

  const [selectedObjectAddress, setSelectedObjectAddress] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const [selectDealMainSum, setSelectedDealMainSum] = useState(null);

  const responsibleRef = React.useRef(null);

  const [responsibleUserData, setResponsibleUserData] = useState([]);
  const [responsibleUser, setResponsibleUser] = useState("Ответственный");
  const [resposibleKey, setResponsiblekey] = useState(0);

  const [formData, updateFormData] = useState({});

  const [masterFotECount, setMasterFotECount] = useState([uuidV4()]);

  const [masterData, setMasterData] = useState([]);
  const [masterFotData, setMasterFotData] = useState({});
  const [remainingFot, setRemainingFot] = useState(0);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState("");

  const [paymentTypeValue, setPaymentTypeValue] = useState("Нал");

  const [companyName, setCompanyName] = useState("");

  const [comment, setComment] = useState("");

  const [subItem, setSubItem] = useState("Подстатья");

  const [cleaningData, setCleaningData] = useState({});
  const [smpData, setSmpData] = useState({});
  const [doorData, setDoorData] = useState({});
  const [prepaymentNPData, setPrepaymentNPData] = useState({});
  const [prepaymentDb, setPrepaymentDb] = useState({
    prepayment_sum: 0,
    np_prepayment_sum: 0,
  });

  const loadNUHelpers = async (objectId) => {
    let conCopy = nContext.helpersData;

    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/nu/helpers/${objectId}`);

      conCopy.main_helper = res.data.helpers_data.main_helper;
      conCopy.np_helper = res.data.helpers_data.np_helper;
      conCopy.output_helper = res.data.helpers_data.output_helper;
      conCopy.output_all_helper = res.data.helpers_data.output_all_helper;
    } else {
      conCopy.main_helper = 0;
      conCopy.np_helper = 0;
      conCopy.output_helper = 0;
      conCopy.output_all_helper = 0;
    }

    nContext.setHelpersData(conCopy);
  };

  const loadprepaymentData = async (objectId) => {
    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/nu/prepayment/${objectId}`);

      setPrepaymentDb(res.data.prepayment_data);
    } else {
      setPrepaymentDb({ prepayment_sum: 0, np_prepayment_sum: 0 });
    }
  };

  const updateCleaningData = (data) => {
    setCleaningData(data);
  };

  const updateSMPData = (data) => {
    setSmpData(data);
  };

  const updateDoorData = (data) => {
    setDoorData(data);
  };

  const updatePrepaymentNPData = (data) => {
    setPrepaymentNPData(data);
  };

  const loadTerminalObjects = async () => {
    let res = await axios.get("/redesk/nu/objects");
    setObjectsList(res.data.objects_data);
  };

  const loadObjectDetail = async (objectId) => {
    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/nu/amega/object/${objectId}`);

      let dealList = res.data.data;
      dealList.forEach((x) => (x.actNumber = x.actNumber.toString()));
      dealList.forEach((x) => (x.masterWageSum = Math.ceil(x.masterWageSum)));
      setObjectDetail(dealList);
    } else {
      dealRef.current.clear();
      setObjectDetail([]);
      setSelectedDeal(null);
    }
  };

  const loadObjectAddress = async (objectId) => {
    if (objectsList.includes(objectId)) {
      let res = await axios.get("/redesk/terminal/fot/object-address", {
        params: { object_id: objectId },
      });
      setSelectedObjectAddress(res.data.object_address);
    } else {
      setSelectedObjectAddress(null);
    }
  };

  const handleChange = (e, k = null) => {
    if (k !== null) {
      updateFormData({ ...formData, [k]: e });
    } else {
      updateFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const loadResponsibleUser = async (objectId) => {
    let FDCopy = { ...formData };

    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/nu/responsible/${objectId}`);

      if (responsibleUserData.includes(res.data.responsible)) {
        setResponsibleUser(res.data.responsible);
        FDCopy["terminal_fot_responsible_user"] = res.data.responsible;
        FDCopy["terminal_fot_object_id"] = objectId;
      } else {
        setResponsibleUser("Ответственный");
        delete FDCopy["terminal_fot_responsible_user"];
        FDCopy["terminal_fot_object_id"] = objectId;
      }
    } else {
      setResponsibleUser("Ответственный");
      delete FDCopy["terminal_fot_responsible_user"];
      FDCopy["terminal_fot_object_id"] = objectId;
    }
    updateFormData(FDCopy);
    setResponsiblekey(resposibleKey + 1);
  };

  const loadMasterData = async (objectId) => {
    if (objectsList.indexOf(objectId) > -1) {
      let res = await axios.get(`/redesk/terminal/fot/master-info/${objectId}`);

      let mData = res.data.masters;
      mData.push("Сам прораб");
      mData.push("Временный мастер");

      setMasterData(res.data.masters);
    } else {
      setMasterData([]);
    }
  };

  const updateFotData = (k, v) => {
    let fd = masterFotData;
    fd[k] = parseInt(v);
    setMasterFotData(fd);
  };

  const removeMasterFot = (e) => {
    let newList = masterFotECount.filter((uuid) => {
      return uuid !== e.target.getAttribute("list-key");
    });
    setMasterFotECount(newList);
  };

  const getDealFot = (deal) => {
    let dealFot =
      parseFloat(deal.masterWageSum) -
      parseFloat(deal.masterWageSpecial) -
      parseFloat(deal.masterWageNP) -
      parseFloat(deal.masterWageCleaning) -
      parseFloat(deal.masterWageDoors);
    return dealFot > 1 ? Math.ceil(dealFot) : 0;
  };

  const getMainSum = (deal) => {
    let mainSum =
      parseFloat(deal.sum) -
      parseFloat(deal.sumNP) -
      parseFloat(deal.sumSpecial) -
      parseFloat(deal.sumCleaning) -
      parseFloat(deal.sumDoors);
    return mainSum;
  };

  const calculateRemainingFot = async () => {
    let remain = selectedDeal ? getDealFot(selectedDeal) : 0;

    for (let k in masterFotData) {
      if (!isNaN(masterFotData[k])) {
        remain -= masterFotData[k];
      }
    }

    setRemainingFot(remain);
  };

  const selectDeal = async (actNumber) => {
    let deal = objectDetail.find((x) => x.actNumber == actNumber);
    setSelectedDeal(deal ? deal : null);
    setRemainingFot(deal ? getDealFot(deal) : 0);
    if (!deal) {
      setCleaningData({});
      setDoorData({});
      setSmpData({});
      setPrepaymentNPData({});
    }
    return;
  };

  const loadResponsibleUserData = async () => {
    let res = await axios.get("/redesk/terminal/responsible-users");
    setResponsibleUserData(res.data.responsible_users);
  };

  const loadCompanyName = async (objectId) => {
    if (objectsList.includes(objectId)) {
      let res = await axios.get(`/redesk/nu/company/${objectId}`);

      setCompanyName(res.data.company_name);
    } else {
      setCompanyName("");
    }
  };

  const compareDraft = () => {
    let draftCopy = [...uContext.userData.draft_data];

    let has_duplicate = false;
    draftCopy.forEach((x) => {
      if (
        Math.abs(x.sum) === Math.ceil(getDealFot(selectedDeal)) &&
        x.responsible_user === formData.terminal_fot_responsible_user &&
        x.object_id === objectId &&
        x.payment_type === paymentTypeValue
      ) {
        has_duplicate = true;
      }
    });

    return has_duplicate;
  };

  const updateDraftData = (newData) => {
    let oldContext = uContext.userData;

    oldContext.draft_data = newData.draft_data;

    uContext.setUserData(oldContext);
  };

  const handleSubmitWithConfirm = (e) => {
    if (objectsList.indexOf(objectId) < 0) {
      alert("ИД объекта указан неправильно!");
      return;
    }

    if (!selectedDeal) {
      alert("Акт не выбран!");
      return;
    }

    if (
      selectedDeal &&
      prepaymentDb?.prepayment_sum < selectedDeal?.prepayment
    ) {
      alert("Предоплата не сходится!");
      return;
    }

    if (!responsibleUserData.includes(formData.terminal_fot_responsible_user)) {
      alert("Ответственный указан неправильно!");
      return;
    }

    if (remainingFot != 0) {
      alert("Сумма ФОТов мастеров должна совпадать с ФОТом основных работ!");
      return;
    }

    if (compareDraft()) {
      setConfirmModalText(
        "Похожая запись уже есть в черновике. Создать еще одну?"
      );
      setShowConfirmModal(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = { ...formData };

    draftSendData["terminal_fot_object_id"] = objectId;
    draftSendData["terminal_fot_payment_type"] = paymentTypeValue;
    draftSendData["terminal_fot_main_sum"] = selectDealMainSum.toFixed(2);
    draftSendData["terminal_fot_comment"] = comment;
    draftSendData["terminal_fot_sub_item"] = subItem;
    draftSendData["terminal_fot_company_name"] = companyName;
    draftSendData["terminal_fot_metric_4"] = selectedDeal.actNumber;

    if (parseInt(selectedDeal.discountValue) != 0) {
      draftSendData["terminal_fot_discount"] = selectedDeal.discountValue;
    }

    if (parseInt(selectedDeal.prepayment) != 0) {
      draftSendData["terminal_fot_prepayment"] = selectedDeal.prepayment;
      draftSendData["terminal_fot_prepayment_payment_type"] = "Нал";
      draftSendData["terminal_fot_prepayment_item_type"] = "Расход";
    }

    if (parseInt(selectedDeal.prepaymentNP) != 0) {
      draftSendData["terminal_fot_prepaymentnp"] = selectedDeal.prepaymentNP;
      draftSendData["terminal_fot_prepaymentnp_item_type"] = "Расход";
      draftSendData = { ...draftSendData, ...prepaymentNPData };
    }

    if (selectedDeal && selectedDeal.sumCleaning != 0) {
      draftSendData["terminal_fot_cleaning"] =
        selectedDeal.sumCleaning - selectedDeal.promotionSumCleaning;
      draftSendData["terminal_fot_cleaning_fot"] =
        selectedDeal.masterWageCleaning;
      draftSendData = { ...draftSendData, ...cleaningData };
    }

    if (selectedDeal && selectedDeal.sumDoors != 0) {
      draftSendData["terminal_fot_door"] = selectedDeal.sumDoors;
      draftSendData["terminal_fot_door_fot"] = selectedDeal.masterWageDoors;
      draftSendData["terminal_fot_door_item_type"] = "Приход";
      draftSendData = { ...draftSendData, ...doorData };
    }

    if (selectedDeal && selectedDeal.sumSpecial != 0) {
      draftSendData["terminal_fot_smp"] = selectedDeal.sumSpecial;
      draftSendData["terminal_fot_smp_fot"] = selectedDeal.masterWageSpecial;
      draftSendData["terminal_fot_smp_item_type"] = "Приход";
      draftSendData = { ...draftSendData, ...smpData };
    }

    if (draftSendData.terminal_fot_sub_item === "Подстатья") {
      delete draftSendData.terminal_fot_sub_item;
    }

    if (
      !draftSendData.terminal_fot_master_fot_0 ||
      draftSendData.terminal_fot_master_fot_0 === null
    ) {
      delete draftSendData.terminal_fot_master_fot_0;
      delete draftSendData.terminal_fot_master_0;
    }

    let categories_sum_client = (({
      sumDismantling,
      sumRough,
      sumClean,
      sumWiring,
      sumPlumbing,
      sumPrep,
    }) => ({
      sumDismantling,
      sumRough,
      sumClean,
      sumWiring,
      sumPlumbing,
      sumPrep,
    }))(selectedDeal);
    draftSendData["terminal_categories_sum_client"] = categories_sum_client;

    let res = await axios.post("/redesk/draft/fot/send", draftSendData, {
      headers: { "X-CSRF-TOKEN": cookies.get("csrf_access_token") },
    });
    updateDraftData(res.data);

    let fmCopy = { ...formData };
    delete fmCopy["terminal_fot_main_sum"];
    delete fmCopy["terminal_fot_discount"];
    updateFormData(fmCopy);
    /*props.changeKey(Math.random());*/
  };

  useEffect(() => {
    loadTerminalObjects();
    loadResponsibleUserData();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form className="mt-2">
        <Row>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_o_id">
              <Form.Label>Ид объекта</Form.Label>
              <Typeahead
                id="terminal_object_id"
                name="terminal_object_id"
                ref={objectRef}
                placeholder="Ид объекта"
                maxResults={7}
                emptyLabel="Нет совпадений"
                paginationText="Показать больше"
                highlightClassName="font-weight-bold"
                onInputChange={(text) => {
                  loadObjectDetail(text);
                  loadObjectAddress(text);
                  loadMasterData(text);
                  setObjectId(text);
                  loadResponsibleUser(text);
                  loadCompanyName(text);
                  loadprepaymentData(text);
                  loadNUHelpers(text);
                }}
                onChange={(selected) => {
                  loadObjectDetail(selected[0]);
                  loadObjectAddress(selected[0]);
                  loadMasterData(selected[0]);
                  setObjectId(selected[0]);
                  loadResponsibleUser(selected[0]);
                  loadCompanyName(selected[0]);
                  loadprepaymentData(selected[0]);
                  loadNUHelpers(selected[0]);
                }}
                options={objectsList}
                size="sm"
                renderMenuItemChildren={(option, props, idx) => (
                  <Highlighter
                    search={props.text}
                    highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                  >
                    {option}
                  </Highlighter>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_o_id">
              <Form.Label>Номер акта</Form.Label>
              <Typeahead
                filterBy={["actNumber"]}
                labelKey="actNumber"
                id="terminal_deal_id"
                name="terminal_deal_id"
                ref={dealRef}
                placeholder="номер акта"
                maxResults={7}
                emptyLabel="Нет совпадений"
                paginationText="Показать больше"
                highlightClassName="font-weight-bold"
                options={objectDetail}
                size="sm"
                disabled={objectDetail.length >= 1 ? false : true}
                onInputChange={(text) => {
                  selectDeal(text);
                }}
                onChange={(selected) => {
                  setSelectedDeal(selected[0]);
                  setRemainingFot(selected[0] && getDealFot(selected[0]));
                  setSelectedDealMainSum(
                    selected[0] && getMainSum(selected[0])
                  );
                }}
                renderMenuItemChildren={(option, props, idx) => (
                  <div
                    style={{ clear: "both", minHeight: "30px" }}
                    class="lh-sm"
                  >
                    <p style={{ float: "left" }}>№ {option.actNumber}</p>
                    <p style={{ float: "right" }}>
                      {window.redesk_app.common.fmtFinValue.format(option.sum)}
                    </p>
                  </div>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_o_a">
              <Form.Label>Адрес</Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_object_address"
                placeholder={selectedObjectAddress}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_s">
              <Form.Label>Сумма основных работ</Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_fot_main_sum"
                placeholder={selectDealMainSum && selectDealMainSum.toFixed(2)}
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_d">
              <Form.Label>Скидка</Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_fot_discount"
                placeholder={selectedDeal && selectedDeal.discountValue}
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_p">
              <Form.Label
                style={{
                  color:
                    prepaymentDb?.prepayment_sum >= selectedDeal?.prepayment ||
                    !selectedDeal
                      ? "black"
                      : "red",
                }}
              >
                {prepaymentDb?.prepayment_sum >= selectedDeal?.prepayment ||
                !selectedDeal
                  ? "Предоплата"
                  : "Предоплата не сходится"}
              </Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_fot_prepayment"
                placeholder={selectedDeal && selectedDeal.prepayment}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_s_i">
              <Form.Label>Подстатья</Form.Label>
              <Form.Select
                value={subItem}
                onChange={(e) => {
                  setSubItem(e.target.value);
                }}
                size="sm"
                defaultValue="Подстатья"
                name="terminal_fot_sub_item"
                aria-label="terminal_fot_sub_item"
              >
                <option key="Подстатья">Подстатья</option>
                <option key="Мехстяжка" value="Мехстяжка">
                  Мехстяжка
                </option>
                <option key="Мехштукатурка" value="Мехштукатурка">
                  Мехштукатурка
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_c">
              <Form.Label>Примечание</Form.Label>
              <Form.Control
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                size="sm"
                autoComplete="off"
                name="terminal_fot_comment"
                placeholder="Примечание"
                type="text"
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_a_p">
              <Form.Label>ФОТ мастеров</Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_fot_calc_fot"
                placeholder={selectedDeal && getDealFot(selectedDeal)}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Form.Group className="mb-3">
              <Form.Label>Ответственный</Form.Label>
              <Typeahead
                key={resposibleKey}
                id="terminal_fot_responsible_user"
                name="terminal_fot_responsible_user"
                ref={responsibleRef}
                labelKey="value"
                placeholder="Ответственный"
                maxResults={7}
                emptyLabel="Нет совпадений"
                paginationText="Показать больше"
                highlightClassName="font-weight-bold"
                options={responsibleUserData}
                size="sm"
                onChange={(selected) => {
                  handleChange(selected[0], "terminal_fot_responsible_user");
                }}
                onInputChange={(text) => {
                  handleChange(text, "terminal_fot_responsible_user");
                }}
                defaultSelected={
                  responsibleUser === "Ответственный" || responsibleUser === ""
                    ? ""
                    : [responsibleUser]
                }
                renderMenuItemChildren={(option, props, idx) => (
                  <Highlighter
                    search={props.text}
                    highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                  >
                    {option}
                  </Highlighter>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className="mb-3" controlId="t_f_p_t">
              <Form.Label>Тип платежа поэтапки</Form.Label>
              <Form.Select
                size="sm"
                name="terminal_fot_payment_type"
                aria-label="terminal_fot_payment_type"
                value={paymentTypeValue}
                onChange={(e) => {
                  setPaymentTypeValue(e.target.value);
                }}
              >
                <option key="Нал" value="Нал">
                  Нал
                </option>
                <option key="Безнал" value="Безнал">
                  Безнал
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={2} style={{ paddingRight: 0 }}>
            <Form.Group className="mb-3" controlId="t_f_f_r">
              <Form.Label>Расписать</Form.Label>
              <Form.Control
                size="sm"
                defaultValue=""
                name="terminal_fot_fot_remaining"
                placeholder={remainingFot}
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={2} className="pt-4" style={{ textAlign: "left" }}>
            <Button
              variant="primary"
              className="mb-3 mt-2 mx-auto"
              size="sm"
              onClick={() => setMasterFotECount([...masterFotECount, uuidV4()])}
            >
              + ФОТ
            </Button>
          </Col>
        </Row>
        <Row>
          <MasterFot
            formData={formData}
            setFormData={updateFormData}
            fotList={masterFotECount}
            onChange={handleChange}
            onInputChange={handleChange}
            options={masterData}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            fotOnChange={handleChange}
            updateFotData={updateFotData}
            delOnClick={removeMasterFot}
            calculateRemainingFot={calculateRemainingFot}
          />
        </Row>
        {selectedDeal && selectedDeal.sumSpecial != 0 ? (
          <Row>
            <AmegaSMP
              sumSpecial={selectedDeal.sumSpecial}
              masterWageSpecial={selectedDeal.masterWageSpecial}
              smpData={smpData}
              updateSMPData={updateSMPData}
            />
          </Row>
        ) : (
          <></>
        )}
        {selectedDeal && selectedDeal.sumCleaning != 0 ? (
          <Row>
            <AmegaCleaning
              finalCleaningSum={
                selectedDeal.sumCleaning - selectedDeal.promotionSumCleaning
              }
              promo={selectedDeal.promotionSumCleaning}
              masterWageCleaning={selectedDeal.masterWageCleaning}
              cleaningData={cleaningData}
              updateCleaningData={updateCleaningData}
            />
          </Row>
        ) : (
          <></>
        )}
        {selectedDeal && selectedDeal.sumDoors != 0 ? (
          <Row>
            <AmegaDoors
              sumDoors={selectedDeal.sumDoors}
              masterWageDoors={selectedDeal.masterWageDoors}
              doorData={doorData}
              updateDoorData={updateDoorData}
            />
          </Row>
        ) : (
          <></>
        )}
        {selectedDeal && selectedDeal.prepaymentNP != 0 ? (
          <Row>
            <AmegaPrepaymentNP
              prepaymentSum={prepaymentDb.np_prepayment_sum}
              prepaymentNP={selectedDeal.prepaymentNP}
              paymentNP={selectedDeal.paymentNP}
              prepaymentNPData={prepaymentNPData}
              updatePrepaymentNPData={updatePrepaymentNPData}
            />
          </Row>
        ) : (
          <></>
        )}
        <Row className="mb-2">
          <Stack gap={2}>
            <Button
              variant="primary"
              className="mb-3 mt-2 mx-auto"
              size="sm"
              onClick={handleSubmitWithConfirm}
            >
              Отправить
            </Button>
          </Stack>
        </Row>
      </Form>
      <Modal
        centered
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Body>
          <p>{confirmModalText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
            Нет
          </Button>
          <Button
            variant="danger"
            style={{ minWidth: "12%" }}
            onClick={(e) => {
              handleSubmit(e);
              setShowConfirmModal(false);
            }}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AmegaTerminal;
