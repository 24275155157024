import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import { Row, Col, Button, Stack, Form, Modal } from "react-bootstrap";
import { UserContext } from "../UserContextManager";
import axios from "axios";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function SupplySpbMainTerminal() {
  const navigate = useNavigate();
  const uContext = useContext(UserContext)

  const [paymentType, setPaymentType] = useState("Нал");
  const [itemType, setItemType] = useState("Расход");
  const [item, setItem] = useState("Статья");
  const [orderType, setOrderType] = useState("Тип заказа");
  const [comment, setComment] = useState("");
  const [sum, setSum] = useState(0);
  const [selectedObjectId, setSelectedObjectId] = useState("");
  const [selectedResponsible, setSelectedresponsible] = useState("");

  const [dropdownsData, setDropdownsData] = useState([]);
  const [objects, setObjects] = useState([]);
  const [responsibles, setResponsibles] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const [responsibleData, setResponsibleData] = useState([]);
  const [defaultResponsibles, setDefaultResponsibles] = useState([]);
  const [responsible, setResponsible] = useState('')

  const objectRef = React.createRef();
  const responsibleRef = React.createRef();

  const loadResponsibleData = async () => {
    let res = await axios.get('/redesk/supply_spb/responsible-users');
    setResponsibleData(res.data.responsible_users);
    setDefaultResponsibles(res.data.default_responsibles)
  }

  const loadSupplySpbDropdowns = async () => {
    try {
      let dropdownsRes = await axios.get("/redesk/supply-spb/dropdowns", {});
      setDropdownsData(dropdownsRes.data.dropdowns);

    } catch (error) {
      navigate("/");
    }
  };

  const loadSupplySpbObjects = async () => {
    try {
      let objectsRes = await axios.get("/redesk/supply-spb/objects", {});

      setObjects(objectsRes.data.objects);
    } catch (error) {
      setObjects([]);
    }
  };

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if(
        Math.abs(x.sum) === Number(sum)
        && x.responsible_user === responsible
        && x.item === item
        && x.payment_type === paymentType
        && x.work_type === orderType
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {
    if (objects.indexOf(selectedObjectId) < 0 && item !== 'Переброска'){
      alert('ИД объекта указан неправильно!')
      return
    }

    if (sum === '' || sum === '0' || sum === 0 || sum === undefined){
      alert('Введите сумму!')
      return
    }

    if (orderType === 'Тип заказа'){
      alert('Укажите тип заказа!')
      return
    }

    if (item === 'Статья'){
      alert('Укажите статью!')
      return
    }

    if (item !== 'Переброска' 
    && defaultResponsibles.concat(responsibleData).indexOf(responsible) < 0 ){
      alert('Ответственный указан неправильно!')
      return
    }

    if (item === 'Переброска' 
    && defaultResponsibles.indexOf(responsible) < 0){
      alert('Ответственный указан неправильно!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let draftSendData = {}
    draftSendData['cfo'] = 'СПб_Снабжение'
    draftSendData['desk_owner'] = 'СПб_Снабжение'
    draftSendData['terminal_object_id'] = selectedObjectId
    draftSendData['terminal_sum'] = sum
    draftSendData['terminal_item_type'] = itemType
    draftSendData['terminal_item'] = item
    draftSendData['terminal_payment_type'] = paymentType
    draftSendData['terminal_work_type'] = orderType
    draftSendData['terminal_responsible_user'] = responsible
    draftSendData['terminal_comment'] = comment


    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);
    setSum('');

  }

  useEffect(() => {
    loadSupplySpbDropdowns();
    loadSupplySpbObjects();
    loadResponsibleData();
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form id="terminal_main_form" className="mt-2">
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_o_id">
            <Form.Label>Ид объекта</Form.Label>
            <Typeahead
              ref={objectRef}
              id="terminal_object_id"
              name="terminal_object_id"
              placeholder="Ид объекта"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              options={objects}
              size="sm"
              onInputChange={(text) => {
                setSelectedObjectId(text);
              }}
              onChange={(selected) => {
                setSelectedObjectId(selected[0]);
              }}
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter
                  search={props.text}
                  highlightClassName="fw-bold mx-0 px-0 bg-transparent"
                >
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete="off"
              id="terminal_sum"
              name="terminal_sum"
              className="form-control"
              style={{ maxHeight: "30px" }}
              value={sum != 0 ? sum : ""}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              onValueChange={(e) => {
                setSum(e);
              }}
              groupSeparator={" "}
            />
          </Form.Group>
        </Col>
        <Col xs={4}></Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_w_t">
            <Form.Label>Тип заказа</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_work_type"
              aria-label="terminal_work_type"
              value={orderType}
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option key="Тип заказа" value="Тип заказа" disabled>
                Тип заказа
              </option>
              {dropdownsData.map(({ value, dropdown_name, spec }, index) => {
                if (dropdown_name === "supply_spb_order_type") {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_i_t">
            <Form.Label>Тип статьи</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_item_type"
              aria-label="terminal_item_type"
              value={itemType}
              onChange={(e) => {
                setItemType(e.target.value);
                setItem("Статья");
              }}
            >
              <option key="Приход">Приход</option>
              <option key="Расход">Расход</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_i_t">
            <Form.Label>Статья</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_item_type"
              aria-label="terminal_item_type"
              value={item}
              onChange={(e) => setItem(e.target.value)}
            >
              <option key="Статья">Статья</option>
              {dropdownsData.map(({ value, dropdown_name, spec }, index) => {
                if (dropdown_name === "supply_spb_item" && spec === itemType) {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }
                return null;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group as={Col} className="mb-3" controlId="t_p_t">
            <Form.Label>Тип платежа</Form.Label>
            <Form.Select
              size="sm"
              name="terminal_payment_type"
              aria-label="terminal_payment_type"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option key="Нал">Нал</option>
              <option key="Безнал">Безнал</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_r_u">
            <Form.Label>Ответственный</Form.Label>
            <Typeahead
              ref={responsibleRef}
              id="terminal_responsible_user"
              name="terminal_responsible_user"
              labelKey="value"
              placeholder="Ответственный"
              maxResults={7}
              emptyLabel="Нет совпадений"
              paginationText="Показать больше"
              highlightClassName="font-weight-bold"
              size="sm"
              onChange={(selected) => {setResponsible(selected[0])}}
              onInputChange={(text) => {setResponsible(text)}}
              options={item === 'Переброска' ? defaultResponsibles : defaultResponsibles.concat(responsibleData)}
              renderMenuItemChildren={(option, props, idx) => (
                <Highlighter search={props.text} highlightClassName='fw-bold mx-0 px-0 bg-transparent'>
                  {option}
                </Highlighter>
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="t_c">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              size="sm"
              autoComplete="off"
              name="terminal_comment"
              placeholder="Примечание"
              type="text"
              value={comment}
              onInput={(e) => setComment(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" size="sm" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  );
}

export default SupplySpbMainTerminal;
